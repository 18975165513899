import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faTrash, faEdit, faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {
  Form, Button, Col, Icon, message, Popconfirm, Typography,
} from 'antd';
import { format } from 'date-fns';
import Card from '../../components/Card';
import Row from '../../components/Row';
import Select from '../../components/Select';
import Spacer from '../../components/Spacer';
import ServerSideTable from '../../components/ServerSideTable';
import PageHeader from '../../components/PageHeader';
import CardImageList from '../../components/CardImageList';
import {
  searchMerchant,
  getPickupDetails,
  getManifestList,
  fetchMerchantPickupDetails,
} from './newDucks';
import Container from '../../components/Container';

export class MerchantListSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilter: [],
      options: {
        merchant: [],
        pickup_manifest: [],
      },
      pickupList: {
        rows: [],
        total: 0,
        pages: 0,
      },
      merchantEpod: [],
      merchantValue: null,
      pickupManifestValue: null,
      isFetching: true,
      isFetchingTable: false,
    };

    this.submitHandlerData = this.submitHandlerData.bind(this);
    // this.onFetchData = this.onFetchData.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  componentDidMount() {
    // console.log('test');

    const { doSearchMerchant } = this.props;

    this.setState({
      isFetching: true,
    });

    doSearchMerchant('L').then((action) => {
      // console.log(action.payload.data.shippers.slice(0, 50))
      this.setState(prevState => ({
        options: {
          ...prevState.options,
          merchant: action.payload.data.shippers.slice(0, 50),
        },
        isFetching: false,
      }));
    });
  }

  // onFetchData(state, instance) {

  // const params = {
  //   export: false,
  //   page: state.page,
  //   pageSize: state.pageSize,
  //   filter: state.filtered,
  //   sorted: state.sorted,
  // };

  // this.setState({
  //   isFetching: true,
  //   resTablePayload: params,
  // });

  // doGetManifestList(params).then((action) => {
  //   this.setState({
  //     pickupList: {
  //       rows: action.payload.data.rows,
  //       total: action.payload.data.total,
  //       pages: action.payload.data.pages,
  //     },
  //     isFetching: false,
  //   });
  // });

  // }

  submitHandlerData() {

    // const params = {
    //   page: 0,
    //   pageSize: 10,
    //   filtered: [],
    //   sorted: [],
    // };

    // this.setState(() => {
    //   this.onFetchData(params);
    // });

    const { doGetManifestList, doFetchMerchantPickupView } = this.props;
    const { pickupManifestValue } = this.state;

    doGetManifestList(pickupManifestValue).then((action) => {
      this.setState(prevState => ({
        ...prevState,
        pickupList: {
          rows: action.payload.data.manifestDetails,
        },
        isFetchingTable: false,
      }));

      doFetchMerchantPickupView(pickupManifestValue).then((res) => {
        this.setState(prevState => ({
          ...prevState,
          merchantEpod: res.payload.data.pod,
        }));

        console.log(res.payload.data);
      });
    });
  }

  searchOption(type, e) {
    const { doSearchMerchant, doGetPickupDetails } = this.props;
    const { merchantValue } = this.state;
    this.setState({
      isFetching: true,
    });

    if (type === 'merchant') {
      doSearchMerchant(e).then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            [type]: action.payload.data.shippers.slice(0, 50),
          },
          isFetching: false,
        }));
      });
    } else {
      doGetPickupDetails(merchantValue, e).then((action) => {
        this.setState(prevState => ({
          ...prevState,
          options: {
            ...prevState.options,
            pickup_manifest: action.payload.data.pickupDetails.slice(0, 50),
          },
          isFetching: false,
        }));
        console.log(action.payload.data.pickupDetails.slice(0, 50));
      });
    }
    // console.log(e);
  }

  selectHandler(type, e) {
    const { doGetPickupDetails } = this.props;

    this.setState({
      isFetching: true,
    });

    if (type === 'merchant') {
      doGetPickupDetails(e, '').then((action) => {
        this.setState(prevState => ({
          options: {
            ...prevState.options,
            pickup_manifest: action.payload.data.pickupDetails.slice(0, 50),
          },
          merchantValue: e,
          isFetching: false,
        }));
        console.log(e);
      });
    } else {
      this.setState(prevState => ({
        ...prevState,
        pickupManifestValue: e,
        isFetching: false,
      }));
    }
  }

  render() {
    const {
      options,
      pickupList,
      isFetching,
      merchantValue,
      pickupManifestValue,
      isFetchingTable,
      merchantEpod,
    } = this.state;

    const { Paragraph } = Typography;
    const { form, user } = this.props;
    const { getFieldDecorator } = form;

    const breadCrumbs = [
      {
        breadcrumbName: 'Packages',
      },
      {
        breadcrumbName: 'Merchant Pickup',
      },
      {
        breadcrumbName: 'Selector',
      },
    ];

    const dispatchPackageSelectorColumns = [
      {
        id: 'tracking_number',
        Header: 'Tracking Number',
        filterable: false,
        sortable: false,
        accessor: data => data.tracking_number,
      },
      {
        id: 'status',
        Header: 'Status',
        filterable: false,
        sortable: false,
        accessor: data => data.status,
      },
      {
        id: 'reference_code',
        Header: 'Reason',
        filterable: false,
        sortable: false,
        accessor: data => data.reference_code,
      },
      {
        id: 'updated_at',
        Header: 'Date Updated',
        filterable: false,
        sortable: false,
        accessor: data => data.updated_at,
      },
      // {
      //   id: 'updated_by',
      //   Header: 'Updated By',
      //   filterable: false,
      //   sortable: false,
      //   accessor: data => data.updated_by,
      // },
    ];

    return (
      <div className="MerchantListSelector">
        <PageHeader title="Merchant Selector" routes={breadCrumbs} />
        <Container>
          <Card title="List Filter">
            <Row>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Merchant">
                  {getFieldDecorator('merchant', {
                    rules: [{ required: true, message: 'Merchant cannot be blank' }],
                  })(<Select
                    allowClear
                    loading={isFetching}
                    options={options.merchant}
                    onSearch={e => this.searchOption('merchant', e)}
                    onSelect={e => this.selectHandler('merchant', e)}
                    filterOption={false}
                    placeholder="Type to search for merchant"
                    showSearch
                    disabled={![100290].includes(user.user_id)}
                    // user.user_id !== 100290
                    style={{ width: '100%' }}
                  />)}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={12}>
                <Form.Item label="Pickup Manifest">
                  {getFieldDecorator('pickup_manifest', {
                    rules: [{ required: true, message: 'Pickup Manifest cannot be blank' }],
                  })(<Select
                    allowClear
                    loading={isFetching}
                    options={options.pickup_manifest}
                    onSearch={e => this.searchOption('pickup_manifest', e)}
                    onSelect={e => this.selectHandler('pickup_manifest', e)}
                    filterOption={false}
                    placeholder="Type to search for pickup manifest"
                    disabled={merchantValue === null}
                    showSearch
                    style={{ width: '100%' }}
                  />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  disabled={pickupManifestValue === null}
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={this.submitHandlerData}
                >
                  <Icon viewBox="0 0 1024 1024">
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                  </Icon>
              Search
                </Button>
              </Col>
            </Row>
          </Card>
          <Spacer />
          <Card
            // title={(
            //   <React.Fragment>
            //     <Row>
            //       <Col xs={24} sm={24} lg={24}>
            //         <Paragraph> Merchant Name : </Paragraph>
            //       </Col>
            //       <Col xs={24} sm={24} lg={24}>
            //         <Paragraph> Merchant Pickup # </Paragraph>
            //       </Col>
            //     </Row>
            //   </React.Fragment>
            //   )}
            extra={`Total: ${pickupList.rows.length}`}
          >
            <ServerSideTable
              data={pickupList.rows}
              pages={pickupList.pages}
              columns={dispatchPackageSelectorColumns}
              loading={isFetchingTable}
              loadingText="Fetching Merchant Pickup Selector. . ."
              onFetchData={this.onFetchData}
            />
          </Card>
        </Container>
        <Spacer />

        <CardImageList
          pod={merchantEpod}
        />
      </div>
    );
  }
}

MerchantListSelector.propTypes = {
  doSearchMerchant: PropTypes.func.isRequired,
  doGetManifestList: PropTypes.func.isRequired,
  doGetPickupDetails: PropTypes.func.isRequired,
  doFetchMerchantPickupView: PropTypes.func.isRequired,
  form: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  doSearchMerchant: searchMerchant,
  doGetPickupDetails: getPickupDetails,
  doGetManifestList: getManifestList,
  doFetchMerchantPickupView: fetchMerchantPickupDetails,
};

const WrappedMerchantListSelector = Form.create({ name: 'MerchantListSelector' })(MerchantListSelector);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedMerchantListSelector);
